import React from 'react'
import { graphql } from 'gatsby'
import Typography from '@mui/material//Typography'
import Grid from '@mui/material//Grid'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default function BlogPage({ data }) {
  const frontmatter = data.mdx.frontmatter

  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <Grid container justifyContent='center'>
        <Grid container direction='column' sx={{maxWidth: '50rem', marginTop: '2rem'}} spacing={1}>
          <Grid item>
            <Typography variant='h1' sx={{fontSize: 'h4.fontSize', fontWeight: 800, textAlign: 'center'}}>
              {data.mdx.frontmatter.title}
            </Typography>
          </Grid>
          <Grid item>
          <Typography sx={{textAlign: 'center'}}>
            {data.mdx.frontmatter.date}
          </Typography>
          </Grid>
          <Grid item sx={{marginTop: '4rem'}}>
            <MDXRenderer localImages={data.mdx.frontmatter.localImages}>
              {data.mdx.body}
            </MDXRenderer>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query BlogPost($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        localImages {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`